import React from "react"
import moment from "moment"
import { Layout, Head, Breadcrumbs, PostCommentSection } from "@common"
import { BlogSection, CallTherapistSection, NewsletterSection } from "@sections"
import "./styles/post.scss"

const Post = ({ pageContext, location }) => {
  let coupon = null
  if (location.search.includes("coupon") || location.search.includes("ref")) {
    coupon = location.search.split("=")[1]
  }
  if (coupon != null) {
    localStorage.setItem("urlCoupon", coupon)
    window.history.replaceState(null, null, location.pathname)
  }
  const { page, posts } = pageContext
  return (
    <Layout
      title={page.title}
      parallaxImg={page.featuredImage}
      subtitle={moment(page.date).format("DD / MM / YYYY")}
    >
      <Head
        seo={pageContext.page.seo}
        siteMetaData={pageContext.page.siteMetaData}
        ogType="article"
      />
      <div className="post">
        <div className="post-content">
          <div className="container">
            <div className="row">
              <div className="col" />
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8">
                <Breadcrumbs
                  elements={[
                    { label: "Blog", url: "/blog/" },
                    {
                      label: page.title,
                      url: `/blog/${page.slug}/`,
                    },
                  ]}
                />
                <div
                  className="post-inner"
                  dangerouslySetInnerHTML={{
                    __html: page.content,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <PostCommentSection post={page} />
        <CallTherapistSection />
        <BlogSection title={"Zobacz inne"} posts={posts} />
        <NewsletterSection />
      </div>
    </Layout>
  )
}

export default Post
